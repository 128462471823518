// extracted by mini-css-extract-plugin
export var sustainability = "sustainability-module--sustainability--tIrfe";
export var row = "sustainability-module--row--2OePB";
export var column = "sustainability-module--column---cSRC";
export var media = "sustainability-module--media--33_LJ";
export var copy = "sustainability-module--copy--1E-Eu";
export var link = "sustainability-module--link--WQGqk";
export var mediaShapes = "sustainability-module--mediaShapes--151mF";
export var mediaShape = "sustainability-module--mediaShape--3g2XU";
export var mediaShapeTitleOverlay = "sustainability-module--mediaShapeTitleOverlay--16PkD";
export var mediaShapeTitle = "sustainability-module--mediaShapeTitle--20bVQ";
export var mediaShape1 = "sustainability-module--mediaShape1--3PYI0";
export var mediaShape2 = "sustainability-module--mediaShape2--3FWOw";
export var mediaShape3 = "sustainability-module--mediaShape3--1uHVM";
export var mediaShape4 = "sustainability-module--mediaShape4--1_Ioz";
export var mediaShape5 = "sustainability-module--mediaShape5--3Ubgn";
export var mediaShape6 = "sustainability-module--mediaShape6--1KnlW";
export var fadeOutAndBack = "sustainability-module--fadeOutAndBack--fwXjs";