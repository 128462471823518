import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Container } from "src/components/container";
import { ArrowLink } from "src/components/arrow-link";
import { useAutoplay } from "src/hooks/useAutoplay";

import * as styles from "./services-clients.module.css";

const serviceColorClasses = [
	"c-text-hunter",
	"c-text-purple",
	"c-text-olive",
	"c-text-dark-blue",
];

const clientColorClasses = [
	"c-text-purple",
	"c-text-olive",
	"c-text-dark-blue",
	"c-text-hunter",
];

interface HomepageServicesClientsProps {
	data: {
		clientsCopy: string;
		clients: string[];
		clientsLink: any;
		servicesCopy: string;
		services: string[];
		servicesLink: any;
	};
}

export const HomepageServicesClients = ({
	data,
}: HomepageServicesClientsProps) => {
	const {
		clientsCopy,
		clients,
		clientsLink,
		servicesCopy,
		services,
		servicesLink,
	} = data;

	const [activeServiceIndex, setActiveServiceIndex] = useState<number>(0);
	const [activeClientIndex, setActiveClientIndex] = useState<number>(0);

	const cycleServices = useCallback(() => {
		setActiveServiceIndex(
			activeServiceIndex === services.length - 1 ? 0 : activeServiceIndex + 1
		);
		setActiveClientIndex(
			activeClientIndex === clients.length - 1 ? 0 : activeClientIndex + 1
		);
	}, [
		activeServiceIndex,
		setActiveServiceIndex,
		activeClientIndex,
		setActiveClientIndex,
	]);

	const { play, stop } = useAutoplay(cycleServices, 2000);

	useEffect(() => {
		play();
	}, [play]);

	return (
		<div className={styles.servicesClients}>
			<Container>
				<div className={styles.row}>
					<div className={cx(styles.column)}>
						<p>{servicesCopy}</p>
						<AnimatePresence exitBeforeEnter>
							{services &&
								services.map((service, index) => {
									if (activeServiceIndex != index) {
										return null;
									}
									return (
										<motion.span
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											key={`service-${index}`}
											className={cx(
												"title",
												serviceColorClasses[index % serviceColorClasses.length],
												styles.title
											)}
										>
											{service}
										</motion.span>
									);
								})}
						</AnimatePresence>
						<ArrowLink link={servicesLink} className={styles.link} />
					</div>
					<div className={cx(styles.column)}>
						<p>{clientsCopy}</p>
						<AnimatePresence exitBeforeEnter>
							{clients &&
								clients.map((client, index) => {
									if (activeClientIndex != index) {
										return null;
									}
									return (
										<motion.span
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											key={`client-${index}`}
											className={cx(
												"title",
												styles.title,
												clientColorClasses[index % serviceColorClasses.length]
											)}
										>
											{client}
										</motion.span>
									);
								})}
						</AnimatePresence>
						<ArrowLink link={clientsLink} className={styles.link} />
					</div>
				</div>
			</Container>
		</div>
	);
};
