import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Media, MediaInterface } from "src/components/media";
import { BlockContent } from "src/components/block-content";
import { useAutoplay } from "src/hooks/useAutoplay";
import * as styles from "./hero.module.css";

interface HeroProps {
	data: {
		media: MediaInterface;
		primaryHeadline: any[];
		secondaryHeadline: any[];
		introCopy: any[];
	};
}

export const HomepageHero = ({ data }: HeroProps) => {
	const { ref, inView } = useInView({
		rootMargin: "400px 0px",
	});

	const [activeHeadline, setActiveHeadline] = useState<"primary" | "secondary">(
		"primary"
	);
	const { media, primaryHeadline, secondaryHeadline, introCopy } = data;

	const cycleHeadlines = useCallback(() => {
		setActiveHeadline(activeHeadline === "primary" ? "secondary" : "primary");
	}, [activeHeadline, setActiveHeadline]);

	const { play, stop } = useAutoplay(cycleHeadlines, 4000);

	useEffect(() => {
		play();
	}, [play]);

	return (
		<div ref={ref} className={cx("ac c-white", styles.hero)}>
			{inView && <Media data={media} className={styles.backgroundMedia} />}
			<AnimatePresence exitBeforeEnter>
				{activeHeadline === "primary" && (
					<motion.div
						initial={{ opacity: 1 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						key="primary"
					>
						<BlockContent
							className={cx(
								"display my025 animate-letters",
								styles.primaryHeadline
							)}
							blocks={primaryHeadline}
							splitLetters={true}
						/>
					</motion.div>
				)}

				{activeHeadline === "secondary" && (
					<motion.div
						initial={{ opacity: 1 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						key="secondary"
					>
						<BlockContent
							className={cx(
								"headline my1 animate-letters",
								styles.secondaryHeadline
							)}
							blocks={secondaryHeadline}
							splitLetters={true}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
