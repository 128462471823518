import React from "react";
import cx from "classnames";
import { Media } from "src/components/media";
import { Container } from "src/components/container";
import { Link } from "src/components/link";
import { ArrowLink } from "src/components/arrow-link";

import { useInstagramFeedData } from "src/hooks/useInstagramFeedData";

import * as styles from "./instagram-feed.module.css";

export const InstagramFeed = () => {
	const { items, handle } = useInstagramFeedData();

	return (
		<div className={cx(styles.instagramFeed, "double-border-top")}>
			<ArrowLink
				type="external"
				copy={`@${handle}`}
				href={`https://instagram.com/${handle}`}
			/>
			{items && items.length > 0 && (
				<ul className={styles.list}>
					{items.map((item: any) => {
						return (
							<li className={styles.item} key={item._key}>
								<Link
									className={styles.itemLink}
									type="external"
									href={item.url}
									ariaLabel="Instagram link"
								>
									<Media className={styles.media} data={item.media} />
								</Link>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};
