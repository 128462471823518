import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { MetaInfo } from "src/types/meta";
import { HomepageHero } from "src/components/homepage/hero";
import { HomepageIntroCopy } from "src/components/homepage/intro-copy";
import { HomepageServicesClients } from "src/components/homepage/services-clients";
import { HomepageWhoWeAre } from "src/components/homepage/who-we-are";
import { HomepageWeDoItAll } from "src/components/homepage/we-do-it-all";
import { HomepageTicker } from "src/components/homepage/ticker";
import { HomepageSustainability } from "src/components/homepage/sustainability";
import { HomepageOptionalModules } from "src/components/homepage/optional-modules";
import { InstagramFeed } from "src/components/instagram-feed";

// import { renderHomePageModules } from "src/utils/renderHomePageModules";

export interface HomePageProps {
	data: {
		main: {
			slug: {
				current: string;
			};
			title: string;
			hero: any;
			servicesClients: {
				clientsCopy: string;
				clients: string[];
				clientsLink: any;
				servicesCopy: string;
				services: string[];
				servicesLink: any;
			};
			whoWeAre: { media: any; link: any; copy: any[] };
			weDoItAll: any;
			sustainability: any;
			optionalModules: any;
			ticker: {
				items: any[];
			};
			modules: [];
		};
		meta: {
			metaTitle?: string;
			metaDescription?: string;
			openImage?: any;
			twitterImage?: any;
		};
	};
	location: any;
}

const HomePage = ({ location, data }: HomePageProps) => {
	const { main, meta } = data;

	// set fallback meta tags from content
	const metaInfo: MetaInfo = {
		metaTitle: meta?.metaTitle,
		metaDescription: meta?.metaDescription,
		openImage: meta?.openImage || main.hero?.media.image,
		twitterImage:
			meta?.twitterImage || meta?.openImage || main.hero?.media.image,
	};

	return (
		<RevealOnMount location={location}>
			<SEO
				metaInfo={metaInfo}
				pagePath={location?.pathname}
				slug={main.slug.current}
			/>
			<div>
				<HomepageHero data={main.hero} />
				<div className="bg-cream z1">
					<HomepageIntroCopy data={main.hero} />
					<HomepageServicesClients data={main.servicesClients} />
					<HomepageWhoWeAre data={main.whoWeAre} />
					<HomepageWeDoItAll data={main.weDoItAll} />
					<HomepageTicker data={main.ticker} />
					<HomepageSustainability data={main.sustainability} />
					{main.optionalModules.show && (
						<HomepageOptionalModules data={main.optionalModules} />
					)}
					<InstagramFeed />
				</div>
			</div>
		</RevealOnMount>
	);
};

export default HomePage;
