import React from "react";
import cx from "classnames";
import { Container } from "src/components/container";
import { Link } from "src/components/link";
import { ArrowLink } from "src/components/arrow-link";
import { BlockContent } from "src/components/block-content";
import { Media } from "src/components/media";
import { ReactComponent as WeDoItAllGraphic } from "src/images/svg/WeDoItAll.svg";
import { useInView } from "react-intersection-observer";

import * as styles from "./we-do-it-all.module.css";

interface Card {
	title: string;
	media: any;
}
const Card = ({ data, link }: { data: Card; link?: any }) => {
	return (
		<Link className={styles.card} link={link} type={link.linkType}>
			<div className={styles.cardBorderDark}>
				<div className={styles.cardBorderLight}>
					<div className={styles.cardInner}>
						<h4 className={cx("body", styles.cardTitle)}>{data.title}</h4>
						<div className={styles.cardMediaContainer}>
							{data.media && <Media data={data.media} />}
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

interface HomepageWeDoItAllProps {
	data: {
		cards: Card[];
		link: any;
		copy: any[];
	};
}

export const HomepageWeDoItAll = ({ data }: HomepageWeDoItAllProps) => {
	const { ref, inView } = useInView({
		threshold: 0.5,
	});

	const { cards, copy, link } = data;

	return (
		<div className={cx(styles.weDoItAll)}>
			<Container>
				<div className={styles.stickyContainer}>
					<div className={styles.graphicContainer}>
						<WeDoItAllGraphic className={styles.graphic} />
					</div>
					<div className={styles.cards}>
						{cards &&
							cards.map((card: any) => (
								<Card data={card} key={card._key} link={data.link} />
							))}
					</div>
				</div>
				<div className={cx("ac")} ref={ref}>
					<BlockContent
						className={cx(
							"title highlight-em",
							inView ? "in-view" : "",
							styles.copy
						)}
						blocks={data.copy}
					/>
					<ArrowLink link={data.link} className={styles.link} />
				</div>
			</Container>
		</div>
	);
};
