import React, { useRef } from "react";
import cx from "classnames";
import { Image } from "src/components/image";
import { Marquee } from "src/components/marquee";

import * as styles from "./ticker.module.css";

interface HomepageTickerProps {
	data: {
		items: any[];
	};
}

export const HomepageTicker = ({ data }: HomepageTickerProps) => {
	const { items } = data;

	return (
		<div className={styles.ticker}>
			<Marquee>
				<>
					{items &&
						items.map((item, index) => {
							switch (item._type) {
								case "textObject":
									return (
										<div className={styles.item} key={item._key}>
											<span className={cx("title")}>
												{item.text && item.text}
											</span>
										</div>
									);
								case "svg":
									return (
										<div className={styles.item} key={item._key}>
											<img
												src={item.asset.url}
												alt={item.alt}
												className={styles.svg}
												width={item.asset.metadata.dimensions.width}
												height={item.asset.metadata.dimensions.height}
											/>
										</div>
									);
							}
						})}
				</>
			</Marquee>
		</div>
	);
};
