import React from "react";
import cx from "classnames";

import * as styles from "./marquee.module.css";

export const Marquee = ({
	className,
	children,
}: {
	className?: string;
	children: any;
}) => {
	const groupCount = 4;
	const marqueeStyle = {
		"--speed": `${children.props.children.length * 4}s`,
		"--move-initial": `${-100 / groupCount}%`,
		"--move-final": `${(-100 / groupCount) * 2}%`,
	} as React.CSSProperties;

	return (
		<div className={cx(styles.marquee, className)} style={marqueeStyle}>
			<div className={styles.inner}>
				{[...Array(4)].map((itemGroup, loopIndex) => {
					return (
						<span className={styles.itemGroup} key={`items-group-${loopIndex}`}>
							{children}
						</span>
					);
				})}
			</div>
		</div>
	);
};
