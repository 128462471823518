import React from "react";
import cx from "classnames";
import { Container } from "src/components/container";
import { Link } from "src/components/link";
import { BlockContent } from "src/components/block-content";
import { Media } from "src/components/media";

import * as styles from "./optional-modules.module.css";

interface TextModuleData {
	label: string;
	link: any;
	title: string;
	copy: any[];
}

const TextModule = ({ data }: { data: TextModuleData }) => {
	return (
		<div className={styles.textModule}>
			<div className={styles.textModuleHeader}>
				<span className="label">{data.label}</span>
				{data.link && (
					<Link link={data.link} type={data.link.linkType}>
						<span className="label underline-on-hover">
							{data.link.linkCopy}
						</span>
					</Link>
				)}
			</div>
			<h3 className={cx("small-body mb1")}>{data.title}</h3>
			<BlockContent className={cx("title my1")} blocks={data.copy} />
		</div>
	);
};

const MediaModule = ({ data }: { data: any }) => {
	return (
		<div className={styles.mediaModule}>
			<Media data={data.media} className={styles.mediaModuleMedia} />
			<div className={styles.mediaModuleCopyColumn}>
				<div>
					<BlockContent blocks={data.title} className={cx("title mb075")} />
					<BlockContent blocks={data.copy} />
				</div>
				<Link
					className={styles.link}
					link={data.link}
					type={data.link.linkType}
				>
					<span className="label underline-on-hover">{data.link.linkCopy}</span>
				</Link>
			</div>
		</div>
	);
};

interface HomepageOptionalModulesProps {
	data: {
		backgroundImage: {
			asset: {
				_ref?: string | undefined;
				_id?: string | undefined;
				url: string;
			};
			alt?: string | undefined;
		};
		modules: any[];
	};
}

export const HomepageOptionalModules = ({
	data,
}: HomepageOptionalModulesProps) => {
	const { backgroundImage, modules } = data;
	return (
		<div className={cx(styles.optionalModules)}>
			<Media
				className={styles.backgroundImage}
				data={{ image: data.backgroundImage }}
			/>

			<Container className={styles.modules}>
				{modules &&
					modules.map((module) => {
						if (!module.show) {
							return;
						}
						switch (module._type) {
							case "homepageNewsModule":
								return <TextModule data={module} key={module._key} />;
							case "homepageMediaCopyModule":
								return <MediaModule data={module} key={module._key} />;
						}
					})}
			</Container>
		</div>
	);
};
