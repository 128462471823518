import React from "react";
import cx from "classnames";
import { Container } from "src/components/container";
import { BlockContent } from "src/components/block-content";
import { useInView } from "react-intersection-observer";

import * as styles from "./intro-copy.module.css";

interface HomepageIntroProps {
	data: any;
}

export const HomepageIntroCopy = ({ data }: HomepageIntroProps) => {
	const { ref, inView } = useInView({
		threshold: 0.5,
	});

	return (
		<div className={styles.introCopy} ref={ref}>
			<Container>
				<BlockContent
					className={cx("ac title highlight-em", inView ? "in-view" : "")}
					blocks={data.introCopy}
				/>
			</Container>
		</div>
	);
};
