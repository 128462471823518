import React from "react";
import cx from "classnames";
import { Container } from "src/components/container";
import { ArrowLink } from "src/components/arrow-link";
import { BlockContent } from "src/components/block-content";
import { Media } from "src/components/media";
import { ReactComponent as GiantTwelve } from "src/images/svg/GiantTwelve.svg";
import { ReactComponent as LinkArrow } from "src/images/svg/ArrowRight.svg";
import { useInView } from "react-intersection-observer";

import * as styles from "./who-we-are.module.css";

interface HomepageWhoWeAreProps {
	data: {
		media: any;
		link: any;
		copy: any[];
	};
}

export const HomepageWhoWeAre = ({ data }: HomepageWhoWeAreProps) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0,
	});

	const { media, copy, link } = data;

	return (
		<div className={cx(styles.whoWeAre, inView ? styles.inView : "")}>
			<Container>
				<div className={styles.row}>
					<div className={styles.column}>
						<Media data={media} className={styles.media} />
					</div>
					<div className={styles.column} ref={ref}>
						<GiantTwelve
							className={cx(styles.giantTwelve, inView ? styles.inView : "")}
						/>
					</div>
				</div>
				<div className={cx("ac")}>
					<BlockContent className={cx("title", styles.copy)} blocks={copy} />
					<ArrowLink link={link} className={styles.link} />
				</div>
			</Container>
		</div>
	);
};
