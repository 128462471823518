import { useStaticQuery, graphql } from "gatsby";
export const useInstagramFeedData = () => {
	const { sanityInstagramFeed } = useStaticQuery(
		graphql`
			query InstagramFeedQuery {
				sanityInstagramFeed {
					items: _rawItems(resolveReferences: { maxDepth: 4 })
					handle
				}
			}
		`
	);

	return sanityInstagramFeed;
};
