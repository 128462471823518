import React, { useEffect, useState, useCallback } from "react";
import cx from "classnames";
import { Container } from "src/components/container";
import { BlockContent } from "src/components/block-content";
import { Media } from "src/components/media";
import { ArrowLink } from "src/components/arrow-link";
import { Link } from "src/components/link";
import { motion } from "framer-motion";
import { useMediaQuery } from "@material-ui/core";
import { useAutoplay } from "src/hooks/useAutoplay";

import { ReactComponent as SustainabilityShape1 } from "src/images/svg/SustainabilityShape1.svg";
import { ReactComponent as SustainabilityShape2 } from "src/images/svg/SustainabilityShape2.svg";
import { ReactComponent as SustainabilityShape3 } from "src/images/svg/SustainabilityShape3.svg";
import { ReactComponent as SustainabilityShape4 } from "src/images/svg/SustainabilityShape4.svg";
import { ReactComponent as SustainabilityShape5 } from "src/images/svg/SustainabilityShape5.svg";
import { ReactComponent as SustainabilityShape6 } from "src/images/svg/SustainabilityShape6.svg";

import { useInView } from "react-intersection-observer";

import * as styles from "./sustainability.module.css";

const masks = [
	SustainabilityShape1,
	SustainabilityShape2,
	SustainabilityShape3,
	SustainabilityShape4,
	SustainabilityShape5,
	SustainabilityShape6,
];

const MobileMediaShapes = ({
	mediaShapes,
	mobileMedia,
	link,
}: {
	mediaShapes: any[];
	mobileMedia: any;
	link: any;
}) => {
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	});

	const [activeTitle, setActiveTitle] = useState<number>(0);

	const cycleTitles = useCallback(() => {
		setActiveTitle(
			activeTitle === mediaShapes.length - 1 ? 0 : activeTitle + 1
		);
	}, [activeTitle, setActiveTitle]);

	const { play, stop } = useAutoplay(cycleTitles, 2000);

	useEffect(() => {
		play();
	}, [play]);

	return (
		<div className={cx(styles.mediaShapes)} ref={ref}>
			<Link className="block" link={link} type={link.linkType}>
				<div className={cx(styles.mediaShape, styles.mediaShape3)}>
					<Media data={mobileMedia} />
				</div>
				<div className={cx(styles.mediaShape, styles.mediaShape4)}></div>
				<div className={cx(styles.mediaShape, styles.mediaShape5)}></div>
				<div className={cx(styles.mediaShape, styles.mediaShape6)}>
					<div className={styles.mediaShapeTitleOverlay}>
						{mediaShapes.map((item, index) => {
							if (activeTitle != index) {
								return null;
							}
							return (
								<motion.span
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									key={`mobile-media-shape-title-${index}`}
									className={cx("label c-cream", styles.mediaShapeTitle)}
								>
									{item.title}
								</motion.span>
							);
						})}
					</div>
				</div>
			</Link>
		</div>
	);
};

const MediaShape = ({ data, index }: { data: any; index: number }) => {
	return (
		<div className={cx(styles.mediaShape, styles[`mediaShape${index + 1}`])}>
			<Media data={data.media} />
			<div className={styles.mediaShapeTitleOverlay}>
				<span className={cx("label c-cream", styles.mediaShapeTitle)}>
					{data.title}
				</span>
			</div>
		</div>
	);
};

interface HomepageSustainabilityProps {
	data: {
		link: any;
		copy: any[];
		mediaShapes: any[];
		mobileMedia: any;
	};
}

export const HomepageSustainability = ({
	data,
}: HomepageSustainabilityProps) => {
	const isDesktop = useMediaQuery("(min-width: 1024px)");

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	});

	const { copy, link, mediaShapes, mobileMedia } = data;

	return (
		<div className={cx(styles.sustainability)}>
			<Container>
				<div className={cx("ac")}>
					<BlockContent className={cx("title", styles.copy)} blocks={copy} />
					<ArrowLink link={link} className={styles.link} />
				</div>
			</Container>

			<div className={cx("visually-hidden")}>
				{mediaShapes &&
					mediaShapes.map((item, index) => {
						const Mask = masks[index];
						return <Mask key={index} />;
					})}
			</div>
			<div ref={ref}>
				{isDesktop ? (
					<div className={cx(styles.mediaShapes)}>
						{mediaShapes.map((item, index) => (
							<Link
								className="block"
								key={item._key}
								link={link}
								type={link.linkType}
							>
								<MediaShape data={item} index={index} />
							</Link>
						))}
					</div>
				) : (
					<MobileMediaShapes
						link={link}
						mediaShapes={mediaShapes}
						mobileMedia={mobileMedia}
					/>
				)}
			</div>
		</div>
	);
};
